.marvel-device {
    display: inline-block;
    position: relative;
    -webkit-box-sizing: content-box !important;
    box-sizing: content-box !important;
}

.marvel-device .screen {
    width: 100%;
    position: relative;
    height: 100%;
    z-index: 3;
    background: white;
    overflow: hidden;
    display: block;
    border-radius: 1px;
    -webkit-box-shadow: 0 0 0 3px #111;
    box-shadow: 0 0 0 3px #111
}

.marvel-device .top-bar,
.marvel-device .bottom-bar {
    height: 3px;
    background: black;
    width: 100%;
    display: block
}

.marvel-device .middle-bar {
    width: 3px;
    height: 4px;
    top: 0px;
    left: 90px;
    background: black;
    position: absolute
}


.marvel-device.iphone8plus {
    width: 414px;
    height: calc(90% - 150px);
    min-height: 500px;
    max-height: 850px;
    padding: 75px 25px;
    background: #d9dbdc;
    border-radius: 56px;
    -webkit-box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2)
}

.marvel-device.iphone8plus:before {
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    position: absolute;
    top: 6px;
    content: '';
    left: 6px;
    border-radius: 50px;
    background: #f8f8f8;
    z-index: 1
}

.marvel-device.iphone8plus:after {
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    position: absolute;
    top: 8px;
    content: '';
    left: 8px;
    border-radius: 48px;
    -webkit-box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 6px 3px #fff;
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 6px 3px #fff;
    z-index: 2
}

.marvel-device.iphone8plus .home {
    border-radius: 100%;
    width: 68px;
    height: 68px;
    position: absolute;
    left: 50%;
    margin-left: -34px;
    bottom: 24px;
    z-index: 3;
    background: #303233;
    background: linear-gradient(135deg, #303233 0%, #b5b7b9 50%, #f0f2f2 69%, #303233 100%)
}

.marvel-device.iphone8plus .home:before {
    background: #f8f8f8;
    position: absolute;
    content: '';
    border-radius: 100%;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    top: 4px;
    left: 4px
}

.marvel-device.iphone8plus .top-bar {
    height: 14px;
    background: #bfbfc0;
    position: absolute;
    top: 68px;
    left: 0
}

.marvel-device.iphone8plus .bottom-bar {
    height: 14px;
    background: #bfbfc0;
    position: absolute;
    bottom: 68px;
    left: 0
}

.marvel-device.iphone8plus .sleep {
    position: absolute;
    top: 190px;
    right: -4px;
    width: 4px;
    height: 66px;
    border-radius: 0px 2px 2px 0px;
    background: #d9dbdc
}

.marvel-device.iphone8plus .volume {
    position: absolute;
    left: -4px;
    top: 188px;
    z-index: 0;
    height: 66px;
    width: 4px;
    border-radius: 2px 0px 0px 2px;
    background: #d9dbdc
}

.marvel-device.iphone8plus .volume:before {
    position: absolute;
    left: 2px;
    top: -78px;
    height: 40px;
    width: 2px;
    border-radius: 2px 0px 0px 2px;
    background: inherit;
    content: '';
    display: block
}

.marvel-device.iphone8plus .volume:after {
    position: absolute;
    left: 0px;
    top: 82px;
    height: 66px;
    width: 4px;
    border-radius: 2px 0px 0px 2px;
    background: inherit;
    content: '';
    display: block
}

.marvel-device.iphone8plus .camera {
    background: #3c3d3d;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 29px;
    left: 50%;
    margin-left: -6px;
    border-radius: 100%;
    z-index: 3
}

.marvel-device.iphone8plus .sensor {
    background: #3c3d3d;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 36px;
    left: 154px;
    z-index: 3;
    border-radius: 100%
}

.marvel-device.iphone8plus .speaker {
    background: #292728;
    width: 70px;
    height: 6px;
    position: absolute;
    top: 41px;
    left: 50%;
    margin-left: -35px;
    border-radius: 6px;
    z-index: 3
}

.marvel-device.iphone8plus.gold {
    background: #f9e7d3
}

.marvel-device.iphone8plus.gold .top-bar,
.marvel-device.iphone8plus.gold .bottom-bar {
    background: white
}

.marvel-device.iphone8plus.gold .sleep,
.marvel-device.iphone8plus.gold .volume {
    background: #f9e7d3
}

.marvel-device.iphone8plus.gold .home {
    background: #cebba9;
    background: linear-gradient(135deg, #cebba9 0%, #f9e7d3 50%, #cebba9 100%)
}

.marvel-device.iphone8plus.black {
    background: #464646;
    -webkit-box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.7);
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.7)
}

.marvel-device.iphone8plus.black:before {
    background: #080808
}

.marvel-device.iphone8plus.black:after {
    -webkit-box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 6px 3px #212121;
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 6px 3px #212121
}

.marvel-device.iphone8plus.black .top-bar,
.marvel-device.iphone8plus.black .bottom-bar {
    background: #212121
}

.marvel-device.iphone8plus.black .volume,
.marvel-device.iphone8plus.black .sleep {
    background: #464646
}

.marvel-device.iphone8plus.black .camera {
    background: #080808
}

.marvel-device.iphone8plus.black .home {
    background: #080808;
    background: linear-gradient(135deg, #080808 0%, #464646 50%, #080808 100%)
}

.marvel-device.iphone8plus.black .home:before {
    background: #080808
}

.marvel-device.iphone8plus.landscape {
    padding: 26px 112px;
    height: 414px;
    width: 736px
}

.marvel-device.iphone8plus.landscape .sleep {
    top: 100%;
    border-radius: 0px 0px 2px 2px;
    right: 190px;
    height: 4px;
    width: 66px
}

.marvel-device.iphone8plus.landscape .volume {
    width: 66px;
    height: 4px;
    top: -4px;
    left: calc(100% - 188px - 66px);
    border-radius: 2px 2px 0px 0px
}

.marvel-device.iphone8plus.landscape .volume:before {
    width: 40px;
    height: 2px;
    top: 2px;
    right: -78px;
    left: auto;
    border-radius: 2px 2px 0px 0px
}

.marvel-device.iphone8plus.landscape .volume:after {
    left: -82px;
    width: 66px;
    height: 4px;
    top: 0;
    border-radius: 2px 2px 0px 0px
}

.marvel-device.iphone8plus.landscape .top-bar {
    width: 14px;
    height: 100%;
    left: calc(100% - 68px - 14px);
    top: 0
}

.marvel-device.iphone8plus.landscape .bottom-bar {
    width: 14px;
    height: 100%;
    left: 68px;
    top: 0
}

.marvel-device.iphone8plus.landscape .home {
    top: 50%;
    margin-top: -34px;
    margin-left: 0;
    left: 24px
}

.marvel-device.iphone8plus.landscape .sensor {
    top: 154px;
    left: calc(100% - 54px - 16px)
}

.marvel-device.iphone8plus.landscape .speaker {
    height: 70px;
    width: 6px;
    left: calc(100% - 59px - 6px);
    top: 50%;
    margin-left: 0px;
    margin-top: -35px
}

.marvel-device.iphone8plus.landscape .camera {
    left: calc(100% - 29px);
    top: 50%;
    margin-left: 0px;
    margin-top: -5px
}