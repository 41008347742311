@font-face {
  font-family: "Quicksand-Bold";
  src: local("Quicksand-Bold"), url('./assets/fonts/Quicksand/Quicksand-Bold.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Quicksand-Light";
  src: local("Quicksand-Light"), url('./assets/fonts/Quicksand/Quicksand-Light.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Quicksand-Medium";
  src: local("Quicksand-Medium"), url('./assets/fonts/Quicksand/Quicksand-Medium.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Quicksand-Regular";
  src: local("Quicksand-Regular"), url('./assets/fonts/Quicksand/Quicksand-Regular.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Quicksand-SemiBold";
  src: local("Quicksand-SemiBold"), url('./assets/fonts/Quicksand/Quicksand-SemiBold.ttf') format("truetype");
  font-display: swap;
}

div {
  scrollbar-width: 'none';
}

html {
  font-size: 14px;
  overscroll-behavior-y: contain;

}

body {
  overflow: hidden;
  overscroll-behavior-y: contain;
}


:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
  height: 100%;
  width: 100%;
  overscroll-behavior-y: contain;

}

.component-app {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
}

input {
  outline: none;
}


.item {
  background: white;
  transform-origin: 30% 50% 0px;
}

.item .dragHandle {
  position: absolute;
  left: -40px;
  top: 3px;
  cursor: move;
  width: 65px;
  height: 55px;
  background: url('grippy.png') 50% 50% no-repeat no-repeat;
}

.item:hover .dragHandle,
.item.dragged .dragHandle {
  z-index: 50;
  visibility: visible;
}


ul {
  padding: 0;
  margin: 0;
}

.noselect {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

text,
div {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}


.ReactVirtualized__List {
  overflow: auto !important;
}

/* width */
div::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(168, 168, 168);
  border-radius: 10px;
}

div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 7px;
}